<script setup lang="ts">
import { getEdgeClasses } from '~/lib/getEdgeClasses'

import { type TextMediaBlockV2, normalizeTextMediaBlockV2 } from '~/types/storyblok/text-media-block-v2'
import { useImage } from '~/composables/useImage'
import type { trackBlockClickEmit } from '~/types/track-block-click'

interface Props {
  blok: TextMediaBlockV2
}

const props = defineProps<Props>()
const emit = defineEmits<trackBlockClickEmit>()

const blok = toRef(props, 'blok')
const textMediaBlock = computed(() => normalizeTextMediaBlockV2(blok.value))
const bgColor = computed(() => textMediaBlock.value.backgroundColor)
const textColor = computed(() => textMediaBlock.value.fontAndCtaColor)

const normalizedText = useRichtext(computed(() => textMediaBlock.value.text))

const { imageMobile, imageAlt } = useImage(
  textMediaBlock.value,
)

function trackBlockClick(url: string) {
  emit('trackBlockClick', url, 'button')
}
</script>

<template>
  <section
    v-editable="blok" :class="[
      { 'media--left': textMediaBlock.isMediaLeft },
      { 'media--right': textMediaBlock.isMediaRight },
      { 'media--top': textMediaBlock.isMediaTop },
      {
        'only-title':
          !textMediaBlock.ctaLabel
          && !textMediaBlock.ctaLink
          && !textMediaBlock.label
          && !textMediaBlock.link
          && !textMediaBlock.text?.content?.find(el => el.content),
      },
      {
        'without-text': !textMediaBlock.text?.content?.find(el => el.content),
      },
      getEdgeClasses(textMediaBlock.edgeStyle),
    ]"
  >
    <div v-if="textMediaBlock.largeAsset" class="media__container">
      <ImageResponsive
        provider="storyblok" class="img" :src="imageMobile" :alt="imageAlt"
        :large-asset="textMediaBlock.largeAsset" :ratio="textMediaBlock.isMediaTop ? '16:9' : 'auto'" mobile-ratio="4:3"
        :lazy="true" object-fit="cover"
      />
      <img
        v-if="textMediaBlock.overlay" class="media__overlay" :src="textMediaBlock.overlay"
        :alt="textMediaBlock.altTextOverlay ?? ''"
      >
    </div>

    <div class="content" :class="[getEdgeClasses(textMediaBlock.edgeStyle)]">
      <div class="content--left">
        <h2 v-if="textMediaBlock.shortTitle" class="content__title content__title--short">
          {{ textMediaBlock.shortTitle }}
        </h2>
        <h2 v-if="textMediaBlock.longTitle" class="content__title content__title--long">
          {{ textMediaBlock.longTitle }}
        </h2>
      </div>
      <div class="content--right">
        <div v-if="textMediaBlock.text" class="content__text" v-html="normalizedText" />
        <ButtonIcon
          v-if="textMediaBlock.ctaLink && textMediaBlock.ctaLabel" class="btn"
          :text="$t(textMediaBlock.ctaLabel)" left-icon="bullet" right-icon="forward" :blinking="true"
          :background-color="textMediaBlock.fontAndCtaColor" :text-color="textMediaBlock.backgroundColor"
          data-test="cta-internal-link-btn" tag="nuxt-link"
          :target="textMediaBlock.ctaLink.startsWith('http') ? '_blank' : '_self'" :to="$url(textMediaBlock.ctaLink)"
          @click="trackBlockClick($url(textMediaBlock.ctaLink || '')) "
        />
        <NuxtLink
          v-if="textMediaBlock.link && textMediaBlock.label" class="text-block__link"
          :to="$url(textMediaBlock.link)" :target="$url(textMediaBlock.link).startsWith('http') ? '_blank' : '_self'"
        >
          {{ textMediaBlock.label }}
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/headings';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';
@import 'assets/scss/rules/breakpoints';

.content {
  display: flex;
  background-color: v-bind(bgColor);
  color: v-bind(textColor);
  padding: 2.4rem 2.4rem 5.6rem 2.4rem;
  @media (max-width: $small-breakpoint) {
    padding: 2.8rem 1.6rem 4rem 1.6rem;
    flex-direction: column;
  }
  &--left {
    width: 100%;
    margin-bottom: 1.6rem;

    @media (min-width: $tablet) {
      margin-right: 4.8rem;
      width: 50%;
    }
  }
  &--right {
    @media (min-width: $tablet) {
      width: 50%;
    }
  }

  &__title {
    &--short {
      @include h3;
      color: v-bind(textColor);

      @media (max-width: $small-breakpoint) {
        @include h4;
        color: v-bind(textColor);
      }
    }
    &--long {
      @include h5;
      color: v-bind(textColor);

      @media (max-width: $small-breakpoint) {
        @include h6;
        color: v-bind(textColor);
      }
    }
  }

  &__text {
    @include body1;
    color: v-bind(textColor);
  }
}

.media {
  &__container {
    position: relative;
    img {
      width: 100%;
    }
  }

  &__overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 8rem;
    @media (min-width: $tablet) {
      height: 14rem;
    }
  }

  &--right, &--left {
    display: flex;
    flex-direction: column;

    .media__container {
      width: 100%;

      @media (min-width: $tablet) {
        width: 50%;
      }
    }

    .content {
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      @media (min-width: $tablet) {
        width: 50%;
        padding-top: 28rem;
      }
      &--left {
        width: 100%;
      }
      &--right {
        width: 100%;
        @media (min-width: $tablet) {
          width: 80%;
        }
      }
    }
  }

  &--right {
        @media (min-width: $tablet) {
            flex-direction: row-reverse;
        }
  }

  &--left {
        @media (min-width: $tablet) {
            flex-direction: row;
        }
  }

  &--top {
    .media__container {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

.without-text {
  .content {
    &--left {
      width: 100%;
    }
  }
}

.only-title {
  .content {
    padding-left: 2.4rem;
    &--left {
      width: 75%;
      padding-left: 0;
    }
  }
}

.text-block__link {
  text-decoration: underline;
  display: block;
  margin-top: 2.4rem;
}

.btn {
  margin-top: 4rem;
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.2rem;
}

.wave {
  @include waveFixed;
  margin-top: -1.2rem;
}
</style>
